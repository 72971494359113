import React, { Component } from 'react';

import appstore from '../../images/appstore.svg'
import icon from '../../images/icon.png'

import { } from '../../devices.min.css'

class ShopPage extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }

  }

  componentDidMount() {

  }

  render() {

    return (
      <React.Fragment>
        <div className="container" style={{ marginTop: 20 + 'px' }}>
          <div className="row">
            <div className="col-lg flex-column align-self-center p-5">
              <a href="https://dominocounter.app"><img src={icon} className="img-fluid icon mb-3" alt="Download Domino Counter on the AppStore"></img></a>
              <h1 className="mt-1 mb-3">Domino Counter</h1>
              <p className="lead">Domino Counter was created to help to count up your score after a game of dominoes. It's 2019, there is no need to count each dot individually! Just match the colors or shapes and you're off to the races. </p>
              <p className="lead">With the Domino Counter Plus in-app purchase, you can count even faster using your camera! Just point your phone at your hand of dominoes and it'll tell you how many pips you've got!</p>
              <p className="lead">Now with over 25K downloads around the world, Domino Counter is the most loved domino counting app on the App Store.</p>
              <center>
                <a href="https://itunes.apple.com/us/app/domino-counter/id485382994?ls=1&mt=8"><img src={appstore} className="img-fluid mt-3 download" alt="Download Domino Counter on the AppStore"></img></a>
              </center>
            </div>
          </div>

          <footer className="py-5">
            <div className="container mx-3" style={{ paddingBottom: 20 + 'px' }}>
              <div className="row">
                <div className="col-12 col-md">
                  <h5>Domino Counter</h5>
                  <small className="d-block mb-3 text-muted">© 2019 Zach Miles</small>
                </div>
                <div className="col-6 col-md">
                  <h5>Features</h5>
                  <ul className="list-unstyled text-small">
                    <li className="text-muted">1 - 15 domino pips</li>
                    <li className="text-muted">Custom double zero value</li>
                    <li className="text-muted">Changeable themes</li>
                    <li className="text-muted">Set each dominoes color</li>
                    <li className="text-muted">Count with your camera!*</li>
                  </ul>
                </div>
                <div className="col-6 col-md">
                  <h5>About — Help</h5>
                  <ul className="list-unstyled text-small">
                    <li><a className="text-muted" href="/faq" rel="noopener noreferrer">FAQ</a></li>
                    <li><a className="text-muted" href="http://zachmil.es" rel="noopener noreferrer">Zach Miles</a></li>
                    <li><a className="text-muted" href="/privacy" rel="noopener noreferrer">Privacy</a></li>
                    <li><a className="text-muted" href="/tos" rel="noopener noreferrer">Terms</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>

          <div className="px-3">
            <p className="small text-muted">* Is a part of the Domino Counter Plus in-app purchase.</p>
          </div>

          <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCenterTitle"><i className="fas fa-question-circle text-primary"></i> How It Works</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>On any page that the embed code is placed in the Domino Counter code initializes an anonymous connection with our Firebase Database at the same path as the current page.</p>
                  <p>When other clients attach to the same place the count increases.</p>
                  <p>When any client disconnects, the count automatically decreases.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

// <li><a className="text-muted" href="#how" data-toggle="modal" data-target="#exampleModalCenter">How it Works</a></li>

export default ShopPage