import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes
} from 'react-router-dom';

import LandingPage from '../Landing';
import ShopPage from '../Shop';

import * as ROUTES from '../../constants/routes';

// import { withAuthentication } from '../Session';

const App = () => (
    <Router>
        <Routes>
            <Route exact path={ROUTES.LANDING} element={<LandingPage/>} />
            <Route exact path={ROUTES.SHOP} element={<ShopPage/>} />
        </Routes>
    </Router>
);

//export default withAuthentication(App);
export default App;