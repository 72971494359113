//import app from 'firebase/app';

import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/messaging';

// const config = {
//     apiKey: process.env.REACT_APP_API_KEY,
//     authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_DATABASE_URL,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   };

  class Firebase {
    constructor() {
      console.log('started fb')
      /*
      app.initializeApp(config);

      this.auth = app.auth();
      this.db = app.database();
      this.functions = app.functions();

      this.messaging = null;
      if (app.messaging.isSupported()) {
        this.messaging = app.messaging();
        this.messaging.usePublicVapidKey("BEfHEeD0rGu7E84bTgJUFNEB2wx-ft0iibH2aGZ1O5AeFE-WortPI8oqWg3M-2dFJTZeJYOoiL8UZXsaYKjCQTE");
      }

      this.auth.signInAnonymously().catch((error) => {
        // Handle Errors here.
        //var errorCode = error.code;
        //var errorMessage = error.message;
        
        //console.log(errorCode, errorMessage)
      })
      */
    }

  }

  export default Firebase;